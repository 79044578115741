<template>
    <div class="fp">
        <div class="fpform">
            <div>
                <p>姓名</p>
                <p>
                    <input type="text" v-model="form.real_name" placeholder="请输入真实姓名"
                        style="text-indent: 10px;">
                </p>
            </div>
            <div>
                <p>证件类型</p>
                <p>
                    <el-select v-model="form.card_type" placeholder="请选择证件类型" style="width:100%">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </p>
            </div>
            <div>
                <p>证件号码</p>
                <p>
                    <input type="text" v-model="form.card_number" placeholder="请输入证件号码" :maxlength="18"
                        style="text-indent: 10px;" @blur="getyear">
                </p>
            </div>
            <div>
                <p>出生日期</p>
                <p>
                    <el-date-picker v-model="form.birthday" type="date" :disabled="disabled" style="width:100%"
                        value-format="yyyy-MM-dd" placeholder="选择日期">
                    </el-date-picker>
                </p>
            </div>
            <div>
                <p>性别</p>
                <p>
                    <el-radio v-model="form.sex" label=1 :disabled="disabled">男</el-radio>
                    <el-radio v-model="form.sex" label=0 :disabled="disabled">女</el-radio>
                </p>
            </div>
            <div>
                <p>类型</p>
                <p>
                    <el-radio v-model="form.type_id" label="1" :disabled="disabled">成人</el-radio>
                    <el-radio v-model="form.type_id" label="2" :disabled="disabled">儿童</el-radio>
                </p>
            </div>

            <div>
                <p>手机号</p>
                <p>
                    <input type="text" v-model="form.phone" placeholder="后续可用于接收出团通知" :maxlength="11"
                        style="text-indent: 10px;">
                </p>
            </div>

            <div class="butt">
                <p @click="add" style="background:#ffffff;color:#FF7C00;width:180px">保存并关闭</p>
                <p @click="next" style="width:180px">保存并添加下一位</p>
            </div>
        </div>
    </div>
</template>
<script>
    import {  adduseroutdoorAPI,CheckIdAPI } from "@/api/user"
    export default {
        props:{
            form:{
			    type:Object
            },
           
	    },
        data() {
            return {
                sign: 1,
                list: [],
                addradio: 0,
                bilradio: 0,
                
               
                //   1=身份证;2=军官证 3=港澳通行证 4=护照 5=回乡证 6=台胞证
                options: [{
                    value: 1,
                    label: '身份证'
                }, {
                    value: 2,
                    label: '军官证'
                }, {
                    value: 3,
                    label: '港澳通行证'
                }, {
                    value: 4,
                    label: '护照'
                }, {
                    value: 5,
                    label: '回乡证'
                }, {
                    value: 6,
                    label: '台胞证'
                }],


            }
        },
        computed: {
            disabled() {
                return this.form.card_type == 1
            }
        },
        created() {
           
        },
        
        methods: {
           async checkidcard(){
              
            },
            getlist(dia) {
               this.$emit("getlist",dia)
            },
            
            add() {
                if (this.form.card_type==1) {
                    var data={
                        name:this.form.real_name,
                        idcard:this.form.card_number
                    }
                     CheckIdAPI(data).then(res=>{
                         adduseroutdoorAPI(this.form).then(res => {
                            this.getlist(false)
                            if (this.form.id == null) {
                                this.$message("添加成功")
                            } else {
                                this.$message("修改成功")
                            }                   
                        })
                    })
                }else{
                    adduseroutdoorAPI(this.form).then(res => {
                        this.getlist(false)
                        if (this.form.id == null) {
                            this.$message("添加成功")
                        } else {
                            this.$message("修改成功")
                        }                   
                    })
                }
                
            },
            next(){
                if (this.form.card_type==1) {
                    var data={
                        name:this.form.real_name,
                        idcard:this.form.card_number
                    }
                     CheckIdAPI(data).then(res=>{
                        adduseroutdoorAPI(this.form).then(res => {
                            this.getlist(true)
                            if (this.form.id == null) {
                                this.$message("添加成功")
                            } else {
                                this.$message("修改成功")
                            }
                    
                        })
                    })
                }else{
                    adduseroutdoorAPI(this.form).then(res => {
                        this.getlist(true)
                        if (this.form.id == null) {
                            this.$message("添加成功")
                        } else {
                            this.$message("修改成功")
                        }
                
                    })
                }
                
            },
            getyear() {
                var birthday = "";
                var sex = "1"
                if (this.form.card_number != null && this.form.card_number != "" && this.form.type_id == 1) {

                    if (this.form.card_number.length == 15) {
                        birthday = "19" + idCard.substr(6, 6);

                    } else if (this.form.card_number.length == 18) {
                        birthday = this.form.card_number.substr(6, 8);

                    }

                    this.form.birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
                    this.form.sex = parseInt(this.form.card_number.substr(16, 1)) % 2 + ""

                    this.form.type_id = this.getAge(this.form.birthday)

                    console.log(this.getAge(this.form.birthday))

                }

            },
            getAge(strAge) {
                const birArr = strAge.split("-");
                const birYear = Number(birArr[0]);
                const birMonth = Number(birArr[1]);
                const birDay = Number(birArr[2]);
                console.log(birYear, birMonth, birDay)
                const today = new Date();
                const nowYear = today.getFullYear();
                const nowMonth = today.getMonth() + 1; //记得加1
                const nowDay = today.getDate();
                console.log(nowYear, nowMonth, nowDay)
                var oldy = nowYear - birYear;
                var oldm = nowMonth - birMonth;
                var oldd = nowDay - birDay;
                console.log(oldy, oldm, oldd)
                if (oldy > 12) {
                    return "1"

                } else if (oldy == 12) {
                    if (oldm > 0) {
                        return "1"
                    } else if (oldm == 0) {
                        if (oldd > 0) {
                            return "1"
                        } else {
                            return "2"
                        }
                    } else {
                        return "2"
                    }
                } else {
                    return "2"
                }

            },
           
        }
    }
</script>

<style lang="scss" scoped>
.fp {
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ff7c00;
        background: #ff7c00;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #ff7c00;
    }

    >p {
        line-height: 40px;
        font-size: 12px;
        color: #999;

        strong {
            font-size: 16px;
            margin-right: 15px;
        }
    }

    .fpform {
        >div {
            display: flex;
            align-items: center;
            line-height: 45px;
            color: #333333;
             
            p:nth-child(1) {
                width: 100px;
            }
             p:nth-child(2) {
                 width: 100%;
            }
            input {
                background: #f6f7f9;
                border-radius: 4px;
                width: 287px;
                height: 35px;
                width: 100%;
            }
        }
    }

    .butt {
        display: flex;
        p {
            width: 180px;
            padding: 0 10px;
            line-height: 35px;
            opacity: 1;
            font-size: 14px;
            background: #ff7c00;
            border-radius: 2px;
            border:1px solid #FF7C00;
            text-align: center;
            color: #ffffff;
            margin: 10px auto;
            cursor: pointer;
        }
    }
}
</style>